import React, { useEffect, useRef, useState } from 'react';
import io from 'socket.io-client';
import WebRTCComponent from './webrtc/WebRTCComponent';

// Signaling server URL (use your own signaling server)
const signalingServer = 'http://10.1.10.100:5000';

function App() {
  // const peerConnectionRef = useRef(null);
  // const localStreamRef = useRef(null);
  // const remoteStreamRef = useRef(null);
  // const [isConnected, setIsConnected] = useState(false); // Track connection state

  // useEffect(() => {
  //   // Connect to the signaling server
  //   const socket = io(signalingServer);

  //   // ICE Servers configuration (STUN and TURN)
  //   const iceServers = [
  //     { urls: 'stun:stun.l.google.com:19302' }, // Google STUN server
  //     { 
  //       urls: 'turn:localhost:3478', 
  //       username: 'test',  // Example username
  //       credential: 'password'  // Example password
  //     }
  //   ];

  //   // Set up RTCPeerConnection with ICE servers
  //   peerConnectionRef.current = new RTCPeerConnection({ iceServers });

  //   // Handle ICE candidate events
  //   peerConnectionRef.current.onicecandidate = (event) => {
  //     if (event.candidate) {
  //       socket.emit('ice-candidate', event.candidate);
  //     }
  //   };

  //   // Handle when a remote stream is added
  //   peerConnectionRef.current.ontrack = (event) => {
  //     remoteStreamRef.current.srcObject = event.streams[0];
  //   };

  //   // Request media stream
  //   navigator.mediaDevices.getUserMedia({ video: true, audio: true })
  //     .then((stream) => {
  //       localStreamRef.current.srcObject = stream;
  //       // Add the local stream to the peer connection
  //       stream.getTracks().forEach(track => peerConnectionRef.current.addTrack(track, stream));
  //     })
  //     .catch((error) => {
  //       console.error("Error getting user media: ", error);
  //     });

  //   // Handle signaling messages
  //   socket.on('offer', (offer) => {
  //     peerConnectionRef.current.setRemoteDescription(new RTCSessionDescription(offer))
  //       .then(() => peerConnectionRef.current.createAnswer())
  //       .then((answer) => {
  //         return peerConnectionRef.current.setLocalDescription(answer);
  //       })
  //       .then(() => {
  //         socket.emit('answer', peerConnectionRef.current.localDescription);
  //       });
  //   });

  //   socket.on('answer', (answer) => {
  //     peerConnectionRef.current.setRemoteDescription(new RTCSessionDescription(answer));
  //   });

  //   socket.on('ice-candidate', (candidate) => {
  //     peerConnectionRef.current.addIceCandidate(new RTCIceCandidate(candidate));
  //   });
    
  //   // Clean up on component unmount
  //   return () => {
  //     socket.disconnect();
  //   };
  // }, []);

  // // Function to initiate the connection (send an offer)
  // const connect = () => {
  //   // Create an offer and send it to the signaling server
  //   peerConnectionRef.current.createOffer()
  //     .then((offer) => {
  //       return peerConnectionRef.current.setLocalDescription(offer);
  //     })
  //     .then(() => {
  //       const socket = io(signalingServer); // Assuming you still need a fresh connection to send offer
  //       socket.emit('offer', peerConnectionRef.current.localDescription);
  //     })
  //     .catch((error) => {
  //       console.error('Error creating offer: ', error);
  //     });

  //   setIsConnected(true); // Update state when connection is initiated
  // };

  return (
    <div>
      {/* <h2>WebRTC Connection</h2>
      
      {!isConnected && (
        <button onClick={connect}>Connect</button>
      )}
      
      <div>
        <h3>Local Stream:</h3>
        <video ref={localStreamRef} autoPlay muted />
      </div>
      
      <div>
        <h3>Remote Stream:</h3>
        <video ref={remoteStreamRef} autoPlay />
      </div> */}
      <WebRTCComponent/>
    </div>
  );
};

export default App;
