
// import React, { useEffect, useRef, useState } from 'react';
// import io from 'socket.io-client';

// const WebRTCComponent = () => {
//     const [socket, setSocket] = useState(null);
//     const [isConnected, setIsConnected] = useState(false);
//     const localVideoRef = useRef(null);
//     const remoteVideoRef = useRef(null);
//     const peerRef = useRef(null);
//     const [roomId, setRoomId] = useState('room-id');
//     const [peerId, setPeerId] = useState(null); // Unique ID for this peer (tab)

//     useEffect(() => {
//         const newSocket = io('http://10.1.10.100:8001');
//         newSocket.on('connect_error', (error) => {
//             console.error('Connection Error: ', error);
//         });
//         setSocket(newSocket);

//         // Generate a unique peer ID for each tab
//         setPeerId(`peer-${Math.random().toString(36).substring(7)}`);

//         return () => {
//             newSocket.disconnect();
//         };
//     }, []);

//     const startCall = async () => {
//         if (!socket) return;

//         const stream = await navigator.mediaDevices.getUserMedia({
//             video: true,
//             audio: true,
//         });

//         localVideoRef.current.srcObject = stream;

//         socket.emit('join-room', roomId, peerId); // Pass peerId along with roomId

//         peerRef.current = new RTCPeerConnection({
//             iceServers: [
//                 {
//                     urls: 'stun:stun.l.google.com:19302',
//                 },
//                 {
//                     urls: 'turn:10.1.10.100:3478',
//                     username: 'prudhvi',
//                     credential: 'Prudhvi@123',
//                 },
//             ],
//         });

//         stream.getTracks().forEach((track) => {
//             peerRef.current.addTrack(track, stream);
//         });

//         peerRef.current.onicecandidate = (event) => {
//             if (event.candidate) {
//                 socket.emit('ice-candidate', event.candidate, roomId, peerId);
//             }
//         };

//         peerRef.current.ontrack = (event) => {
//             // Ensure that this is the remote stream and it is correctly assigned
//             remoteVideoRef.current.srcObject = event.streams[0];
//         };

//         socket.on('offer', async (offer, senderPeerId) => {
//             if (senderPeerId !== peerId) {
//                 await peerRef.current.setRemoteDescription(
//                     new RTCSessionDescription(offer)
//                 );
//                 const answer = await peerRef.current.createAnswer();
//                 await peerRef.current.setLocalDescription(answer);
//                 socket.emit('answer', answer, roomId, peerId);
//             }
//         });

//         socket.on('answer', async (answer, senderPeerId) => {
//             if (senderPeerId !== peerId) {
//                 await peerRef.current.setRemoteDescription(
//                     new RTCSessionDescription(answer)
//                 );
//             }
//         });

//         socket.on('ice-candidate', async (candidate, senderPeerId) => {
//             if (senderPeerId !== peerId) {
//                 await peerRef.current.addIceCandidate(new RTCIceCandidate(candidate));
//             }
//         });

//         // Create and send offer if it's the first peer
//         if (socket.id && !isConnected) {
//             const offer = await peerRef.current.createOffer();
//             await peerRef.current.setLocalDescription(offer);
//             socket.emit('offer', offer, roomId, peerId);
//         }

//         setIsConnected(true);
//     };

//     return (
//         <div>
//             <div>
//                 <button onClick={startCall}>Start Call</button>
//             </div>
//             <div>Local video</div>
//             <video ref={localVideoRef} autoPlay muted style={{ width: '50%' }} />
//             <div>Remote video</div>
//             <video ref={remoteVideoRef} autoPlay style={{ width: '50%' }} />
//         </div>
//     );
// };

// export default WebRTCComponent;



// Updated code 

import React, { useEffect, useRef, useState } from 'react';
import io from 'socket.io-client';
 
const WebRTCComponent = () => {
    const [socket, setSocket] = useState(null);
    const [isConnected, setIsConnected] = useState(false);
    const localVideoRef = useRef(null);
    const remoteVideoRef = useRef(null);
    const peerRef = useRef(null);
    const [roomId, setRoomId] = useState('room-id');
    const [peerId, setPeerId] = useState(null);
 
    useEffect(() => {
        const newSocket = io('https://voicecall.krify.com:8001');
        newSocket.on('connect_error', (error) => {
            console.error('Connection Error: ', error);
        });
        setSocket(newSocket);
 
        const storedPeerId = localStorage.getItem('peerId');
        if (!storedPeerId) {
            const newPeerId = `peer-${Math.random().toString(36).substring(7)}`;
            localStorage.setItem('peerId', newPeerId);
            setPeerId(newPeerId);
        } else {
            setPeerId(storedPeerId);
        }
        console.log(storedPeerId,"stored peer id ")
        return () => {
            newSocket.disconnect();
        };
    }, []);
 
    const startCall = async () => {
        if (!socket) return;
 
        const stream = await navigator.mediaDevices.getUserMedia({
            video: true,
            audio: true,
        });
 
        localVideoRef.current.srcObject = stream;
 
        socket.emit('join-room', { roomId, peerId });

        console.log("Socket emitted join-room with roomId:", roomId, "peerId:", peerId);
        peerRef.current = new RTCPeerConnection({
            iceServers: [
                {
                    urls: 'stun:stun.l.google.com:19302',
                },
                {
                    urls: 'turn:10.1.10.100:3478',
                    username: 'prudhvi',
                    credential: 'Prudhvi@123',
                },
            ],
        });
        console.log(peerRef.current,"curret peer red")
        stream.getTracks().forEach((track) => {
            peerRef.current.addTrack(track, stream);
        });
 
        peerRef.current.onicecandidate = (event) => {
            if (event.candidate) {
                console.log('Sending ICE candidate:', event.candidate);
                socket.emit('ice-candidate', { candidate: event.candidate, roomId, peerId });
            }
        };
 
        peerRef.current.ontrack = (event) => {
            console.log('Remote track received:', event);
            if (event.streams && event.streams[0]) {
                remoteVideoRef.current.srcObject = event.streams[0];
            } else {
                console.error('No streams in event:', event);
            }
        };
 
        peerRef.current.oniceconnectionstatechange = () => {
            console.log('ICE connection state changed:', peerRef.current.iceConnectionState);
            if (peerRef.current.iceConnectionState === 'failed') {
                console.error('ICE connection failed!');
            }
        };
 
        socket.on('offer', async (offer, senderPeerId) => {
            console.log(senderPeerId, peerId,"sender receiving peerids")
            if (senderPeerId !== peerId) {
                console.log("Setting remote description for offer:", offer);
                await peerRef.current.setRemoteDescription(new RTCSessionDescription(offer));
                const answer = await peerRef.current.createAnswer();
                await peerRef.current.setLocalDescription(answer);
                socket.emit('answer', { answer, roomId, peerId });
            }
        });
 
        socket.on('answer', async (answer, senderPeerId) => {
            if (senderPeerId !== peerId) {
                await peerRef.current.setRemoteDescription(new RTCSessionDescription(answer));
            }
        });
 
        socket.on('join-room', ({ roomId, peerId }) => {
            alert("joinng the room")
            socket.join(roomId);
            console.log(`User ${peerId} joined room ${roomId}`);
            socket.to(roomId).emit('user-joined', peerId);
        });
        console.log(socket.id,"socket id")
        if (socket.id && !isConnected) {
            const offer = await peerRef.current.createOffer();
            await peerRef.current.setLocalDescription(offer);
            socket.emit('offer', { offer, roomId, peerId });
        }
        console.log("Connected to room successfully")
        setIsConnected(true);
    };
 
    useEffect(() => {
        return () => {
            if (peerRef.current) {
                peerRef.current.close();
            }
            if (socket) {
                socket.disconnect();
            }
        };
    }, [peerRef.current, socket]);
 
    return (
        <div>
            <div>
                <button onClick={startCall}>Start Call</button>
            </div>
            <div>Local video</div>
            <video ref={localVideoRef} autoPlay muted style={{ width: '50%' }} />
            <div>Remote video</div>
            <video ref={remoteVideoRef} autoPlay style={{ width: '50%' }} />
        </div>
    );
};
 
export default WebRTCComponent;
 

